import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Button,
  Drawer,
  Typography,
  Avatar,
  Stack,
} from '@mui/material';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
import { MHidden } from '../../components/@material-extend';
//
import sidebarConfig from './SidebarConfig';
import sidebarConfigManager from './SidebarConfigManager';
import managerList from '../../config/managerList.json';
import account from '../../_mocks_/account';
import sidebarConfigSuperAdmin from './SidebarConfigSuperAdmin';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  var { isManager } = false;

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname, isManager]);

  const TestIfManager = () => {
    //console.log(managerList);
    //console.log(localStorage.getItem("user_trello_id"))
    isManager = false;
    if (managerList.includes(localStorage.getItem('user_trello_id'))) {
      // console.log('manager');
      isManager = true;
    } else {
      // console.log('non manager');
    }
    return isManager;
  };

  function ChooseSidebarConfig(props) {
    var isManager = TestIfManager();
    //const isManager = TestIfManager;
    if (
      ['563b59813f2d28b48cb95ee9', '5be1b1ce8575750d1318e884', '5ac36829e05704b865343268'].includes(
        localStorage.user_trello_id,
      )
    ) {
      //console.log('super admin')
      return <NavSection navConfig={sidebarConfigSuperAdmin} />;
    } else if (isManager) {
      //console.log('return manager')
      return <NavSection navConfig={sidebarConfigManager} />;
    } else {
      //console.log('return non manager')
      return <NavSection navConfig={sidebarConfig} />;
    }
  }

  const renderContent = (
    <Scrollbar
      sx={{
        height: '100%',
        '& .simplebar-content': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}>
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box component={RouterLink} to='/' sx={{ display: 'inline-flex' }}>
          <Logo />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline='none' component={RouterLink} to='#'>
          <AccountStyle>
            <Avatar src={account.photoURL} alt='photoURL' />
            <Box sx={{ ml: 2 }}>
              <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
                {account.displayName}
              </Typography>
              <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                {account.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>
      <ChooseSidebarConfig isManager={isManager} />
      {/* <NavSection navConfig={sidebarConfig} /> */}

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{
            p: 2.5,
            pt: 5,
            borderRadius: 2,
            position: 'relative',
            bgcolor: 'grey.200'
          }}
        >
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button
            fullWidth
            href="https://material-ui.com/store/items/minimal-dashboard/"
            target="_blank"
            variant="contained"
          >
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width='lgUp'>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}>
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width='lgDown'>
        <Drawer
          open
          variant='persistent'
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
            },
          }}>
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
