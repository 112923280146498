import { Icon } from '@iconify/react';
// import trelloFill from '@iconify/icons-eva/trello-fill';
// import { Icon } from '@iconify/react';

// material
import { Stack, Button } from '@mui/material';

// ----------------------------------------------------------------------

export default function AuthTrello({ trelloAuth }) {
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Button fullWidth size="large" color="inherit" variant="outlined" onClick={trelloAuth}>
          <Icon icon="logos:trello" height={24} />
        </Button>
      </Stack>
    </>
  );
}
