import { useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import { MHidden } from "../components/@material-extend";
import AuthTrello from "../components/authentication/AuthTrello";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

const Login = () => {
  const navigate = useNavigate();

  const isLoggedIn = () => {
    const token = window.location?.hash.replace("#token=", "");
    if (token) {
      localStorage.setItem("user_token", token);
      return true;
    }
    return false;
  };

  if (isLoggedIn()) {
    navigate("/dashboard/app", { replace: true });
  }

  const handleTrelloAuth = () => {
    const apiKey = 'ae073b471b1634609d1c64de12390012';
    const link = `https://trello.com/1/authorize?return_url=${window.location.origin}/dashboard/app&expiration=never&name=TrelloDashboard&scope=read,write,account&response_type=token&key=${apiKey}`;
    window.location.href = link;
  };

  return (
    <RootStyle title="Login | Minimal-UI">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Sign in using Trello
            </Typography>
          </Stack>
          <AuthTrello trelloAuth={handleTrelloAuth} />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Login;
