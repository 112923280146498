import { Icon } from "@iconify/react";

// material
import { alpha, styled } from "@mui/material/styles";
import { Card, Typography } from "@mui/material";
// utils
import { fShortenNumber } from "../../../utils/formatNumber";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: "none",
  textAlign: "center",
  padding: theme.spacing(5, 0),
  color: theme.palette.primary.darker,
  backgroundColor: theme.palette.primary.lighter,
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: "center",
  marginBottom: theme.spacing(3),
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

export default function AppRespectedEstimation({ data }) {
  const estimation = {
    low: { duration: 0, tally: 0 },
    medium: { duration: 0, tally: 0 },
    mediumPlus: { duration: 0, tally: 0 },
    high: { duration: 0, tally: 0 },
  };
  for (const card of data.cards ?? []) {
    if (card.difficulty === 1) {
      estimation.low.tally++;
      estimation.low.duration += card.duration;
    }
    if (card.difficulty === 2) {
      estimation.medium.tally++;
      estimation.medium.duration += card.duration;
    }
    if (card.difficulty === 3) {
      estimation.mediumPlus.tally++;
      estimation.mediumPlus.duration += card.duration;
    }
    if (card.difficulty === 4) {
      estimation.high.tally++;
      estimation.high.duration += card.duration;
    }
  }

  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon="bi:clock-fill" width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant="h5">
        <p>
          low: {(estimation.low.duration / estimation.low.tally).toFixed(1)}{" "}
          hours ({estimation.low.tally} cards)
        </p>
        <p>
          medium: 
          {(estimation.medium.duration / estimation.medium.tally).toFixed(
            1
          )}{" "}
          hours ({estimation.medium.tally} cards)
        </p>
        <p>
          mediumPlus: 
          {(estimation.mediumPlus.duration / estimation.mediumPlus.tally).toFixed(
            1
          )}{" "}
          hours ({estimation.mediumPlus.tally} cards)
        </p>
        <p>
          high: 
          {(estimation.high.duration / estimation.high.tally).toFixed(1)} hours
          ({estimation.high.tally} cards)
        </p>
      </Typography>
    </RootStyle>
  );
}
