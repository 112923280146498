import { Icon } from '@iconify/react';

// material
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.warning.darker,
  backgroundColor: theme.palette.warning.lighter,
  minHeight: '100%',
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  minHeight: '100%',
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.warning.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.warning.dark,
    0,
  )} 0%, ${alpha(theme.palette.warning.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

export default function AppActivityRate({ data }) {
  return (
    <RootStyle>
      <IconWrapperStyle>
        <Icon icon='ic:twotone-speed' width={24} height={24} />
      </IconWrapperStyle>
      <Typography variant='h3'>
        {`${
          data.activityRate > 0 ? `${data.activityRate.toFixed(1)}%` : 'no data'
        }`}
      </Typography>
      <Typography variant='h5'>
        {'> '}
        {`${data.goalActivityRate ? data.goalActivityRate : 0}`}%
      </Typography>
      <Typography variant='subtitle2' sx={{ opacity: 0.72 }}>
        Activity Rate (achievement:{' '}
        {(
          ((data.activityRate > 0 ? data.activityRate : 0) /
            data.goalActivityRate) *
          100
        ).toFixed(1)}
        %)
      </Typography>
    </RootStyle>
  );
}
