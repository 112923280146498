/* eslint-disable no-loop-func */
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

// material
import {
  Box,
  Grid,
  Container,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from '@mui/material';
import { display } from '@mui/system';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppEstimationTime,
  AppTrelloQualityScore,
  AppActivityRate,
  AppNewsUpdate,
  AppRespectedEstimation,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates,
  AppCDC,
  AppClientActivity,
  AppNPS,
  AppCampaigns,
  AppCDCBA,
} from '../components/_dashboard/app';

import boardMembers from '../config/trelloBoardMembers.json';
import brogglEntries from '../config/brogglJsonExampleQ3.json';
import tseMembers from '../config/baMembers.json';
import baMembers from '../config/baMembers.json';

import exportJanvier2022 from '../config/exportJanvier2022.json';
import exportTimeById from '../config/exportTimeById.json';
import setConnectedData from '../layouts/SetConnectedData';

import locationURLs from '../config/location.json';

if (
  localStorage.getItem('user_token') &&
  !localStorage.getItem('user_trello_id') &&
  !localStorage.getItem('user_trello_photo') &&
  !localStorage.getItem('user_email')
) {
  setConnectedData();
}

const monthByQuarter = {
  Q3_2022: '["01/2022","02/2022","03/2022"]',
  Q4_2022: '["04/2022","05/2022","06/2022"]',
  Q1_2023: '["07/2022","08/2022","09/2022"]',
  Q2_2023: '["10/2022","11/2022","12/2022"]',
  Q3_2023: '["01/2023","02/2023","03/2023"]',
  Q4_2023: '["04/2023","05/2023","06/2023"]',
  Q1_2024: '["07/2023","08/2023","09/2023"]',
  Q2_2024: '["10/2023","11/2023","12/2023"]',
  Q3_2024: '["01/2024","02/2024","03/2024"]',
  Q4_2024: '["04/2024","05/2024","06/2024"]',
  Q1_2025: '["07/2024","08/2024","09/2024"]',
  Q2_2025: '["10/2024","11/2024","12/2024"]',
};

//here to change the quarter at loading
var months = monthByQuarter["Q2_2025"];

//var fullName = "";
var nullDataTemplate = [
  {
    id: '000',
    fullName: 'No Data',
    username: 'nodata',
    cards: [
      {
        testId: '000000',
        startDate: '2021-10-22T07:57:30.711Z',
        difficulty: 2,
        timeDuration: 6.5,
        estimationRespected: false,
      },
    ],
    averageCdc: null,
    cdcTally: 0,
    averageDifficulty: 0,
    difficultyTally: 0,
    difficultyScore: 0,
    respectedEstimation: 0,
    respectedEstimationTally: 0,
    trelloWorkRate: 0,
    goalTrelloQualityScore: '0',
    goalRespectedEstimation: '0',
    goalActivityRate: '0',
    weightedWorkRate: 0,
    tqs: 0,
    achievement: 0,
  },
];

var tsesData = [];
var tseMembersTeam;

function getTSEJson(quarterData, tseMembersTeam) {
  for (var key in quarterData) {
    //console.log(quarterData[key]);
    //console.log(quarterData[key].fullName);

    var memberData = quarterData[key];
    var memberId = memberData.id;

    tseMembersTeam.map((tse, index) => {
      if (tse.id === memberId) {
        //initiate new entries
        memberData.averageCdc = 0;
        memberData.cdcTally = 0;
        memberData.averageDifficulty = 0;
        memberData.difficultyTally = 0;
        memberData.difficultyScore = 0;
        memberData.respectedEstimation = 0;
        memberData.respectedEstimationTally = 0;

        //add specific data into the new array
        memberData.fullName = tse.brogglName;
        memberData.trelloWorkRate = tse.trelloWorkRate;
        memberData.goalTrelloQualityScore = tse.trelloQualityScore;
        memberData.goalRespectedEstimation = tse.timeDevEstimation;
        memberData.goalActivityRate = tse.activityRate;

        //CDC regulation
        memberData.cdcList = [];

        //add datas for each card of the tse
        if (memberData?.cards) {
          for (const card of memberData.cards) {
            if (!card) continue;

            card.timeDuration = addTimePassedOnCard(card);

            if (card?.cdc) {
              memberData.averageCdc += card.cdc;
              memberData.cdcList.push(card.cdc);
              memberData.cdcTally++;
            }
            if (card?.difficulty) {
              memberData.averageDifficulty += card.difficulty;
              memberData.difficultyTally++;
              memberData.difficultyScore += card.difficulty;
            }

            if (card?.difficulty && card?.timeDuration) {
              if (card.difficulty == 1 && card.timeDuration < 4) {
                card.estimationRespected = true;
                memberData.respectedEstimation++;
                memberData.respectedEstimationTally++;
              } else if (card.difficulty == 2 && card.timeDuration < 8) {
                card.estimationRespected = true;
                memberData.respectedEstimation++;
                memberData.respectedEstimationTally++;
              } else if (card.difficulty == 4 && card.timeDuration < 12) {
                card.estimationRespected = true;
                memberData.respectedEstimation++;
                memberData.respectedEstimationTally++;
              } else if (card.difficulty == 5) {
                card.estimationRespected = true;
                memberData.respectedEstimation++;
                memberData.respectedEstimationTally++;
              } else {
                card.estimationRespected = false;
                memberData.respectedEstimationTally++;
              }
            }
          }

          memberData.respectedEstimation = memberData.respectedEstimation * 100;
          // console.log(`>=20`, memberData);
          let memberCDCTotal = 0;
          for (
            let i = +(memberData.cdcList.length * 0.05).toFixed(0);
            i <
            memberData.cdcList.length -
              +(memberData.cdcList.length * 0.05).toFixed(0);
            i++
          ) {
            memberCDCTotal += memberData.cdcList[i];
          }
          // console.log(
          //   'ancien cdc',
          //   memberData.averageCdc / memberData.cdcTally,
          // );
          // console.log('total CDC', memberCDCTotal);
          memberData.averageCdc =
            memberCDCTotal / memberData.cdcTally -
            +(memberData.cdcList.length * 0.1).toFixed(0);
          memberData.averageDifficulty =
            Math.round(
              (memberData.averageDifficulty / memberData.difficultyTally) * 100,
            ) / 100;

          memberData.respectedEstimation =
            memberData.respectedEstimation /
            memberData.respectedEstimationTally;
        }

        //add new complete entries for this TSE in the final array
        tsesData.push(memberData);
        //console.log(tsesData);
        tsesData.workRateTally = tsesData.workRateTally
          ? tsesData.workRateTally + parseFloat(memberData.trelloWorkRate)
          : memberData.trelloWorkRate;
        tsesData.cdcTally = tsesData.cdcTally
          ? tsesData.cdcTally + memberData.cdcTally
          : memberData.cdcTally;
        tsesData.difficultyTally = tsesData.difficultyTally
          ? tsesData.difficultyTally + memberData.difficultyTally
          : memberData.difficultyTally;
        tsesData.difficultyScoreTally = tsesData.difficultyScoreTally
          ? tsesData.difficultyScoreTally + memberData.difficultyScore
          : memberData.difficultyScore;
        tsesData.cdcAverage = tsesData.cdcAverage
          ? tsesData.cdcAverage + memberData.averageCdc
          : memberData.averageCdc;
      }
    });
  }
}

function getTimeById(idTest) {
  return exportTimeById[idTest];
}

function addTimePassedOnCard(card) {
  var timePassed = getTimeById(card.testId);
  return timePassed;
}

function calculateTSEQuarterResults(tsesData) {
  var workRateTally = tsesData.workRateTally;
  var difficultyScoreTally = tsesData.difficultyScoreTally;
  let cdcAverage = tsesData.cdcAverage;

  tsesData.averageCdc = cdcAverage / tsesData.length;

  tsesData.map((tse) => {
    tse.weightedWorkRate = tse.trelloWorkRate / workRateTally;
    tse.tqs = tse.weightedWorkRate * difficultyScoreTally * 0.95;
    tse.achievement = (tse.difficultyScore / tse.tqs) * 100;

    tsesData.tqsTally = tsesData.tqsTally
      ? tsesData.tqsTally + tse.tqs
      : tse.tqs;

    //add activity rate
    //if(sessionStorage.getItem('dashboardName') != "No Data") {
    //  TSEactivityRate();
    //}
  });

  return tsesData;
}

const getQuarterData = async () => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${
        locationURLs[process.env.REACT_APP_URL] || ''
      }/getMonthlyData?months=${months}`,
    );
    var quarterData = await response.json();
    resolve(quarterData);
  });
};

const getActivityData = async (fullName) => {
  //fullName = sessionStorage.getItem('dashboardName');
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${
        locationURLs[process.env.REACT_APP_URL] || ''
      }/getActivityRate?months=${months}&name=${fullName}`,
    );
    //const response = await fetch(
    //  `${"http://localhost:3001"}/getMonthlyData?months=["01/2022", "02/2022", "03/2022"]`
    //);
    var activityData = await response.json();
    resolve(activityData);
  });
};

function calculateActivityRate(activityData) {
  let totalHours = 0;
  let activityHours = 0;
  for (const month in activityData) {
    activityHours += activityData[month].activity;
    totalHours +=
      activityData[month].totalHours - activityData[month].offDays * 8;
  }

  return (activityHours / totalHours) * 100;
}

const TSEactivityRate = async (allTseData) => {
  for (var tse of allTseData) {
    // console.log(tse);
    var activityData = await getActivityData(tse.fullName);
    var activityRate = calculateActivityRate(activityData);
    tse.activityRate = activityRate;
    //console.log(tse)
  }
  return allTseData;
};

const key = 'e478e9b2b63fff839170a52eb5ad9c22';
const secret =
  '41e5700bde382ea031a14816635e9629302169afa834d2d878a2f7ce205e6bc8';

const trelloRequestOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
  },
};

const getAllTseData = function async(tsesData) {
  return tsesData;
};

const DashboardBA = () => {
  //const [tsesData, setTsesData] = useState([]);
  const [allTseData, setAllTseData] = useState(nullDataTemplate);
  const [cardsWithoutDueDate, setCardsWithoutDueDate] = useState([]);
  const [tqsArray, setTqsArray] = useState([]);
  const [countryData, setCountryData] = useState([]);

  const [tsesGlobalData, setTsesGlobalData] = useState([]);
  const [globalOKRData, setGlobalOKRData] = useState({
    'client activity': 0,
    detail: [],
  });
  const [globalNPS, setGlobalNPS] = useState({
    nps: 0,
  });

  const [view, setView] = useState([]);

  const [quarterMonth, setQuarterMonth] = useState(months);

  useEffect(async () => {
    await fetch(
      `${locationURLs[process.env.REACT_APP_URL] || ''}/getGlobalOKR`,
      {
        method: 'post',
        body: JSON.stringify({ name: 'client activity', months: quarterMonth }),
        headers: { 'Content-type': 'application/json' },
      },
    ).then((response) =>
      response.json().then((json) =>
        setGlobalOKRData({
          'client activity': json.data || 0,
          detail: json.detail || [0, 0, 0],
        }),
      ),
    );
    await fetch(
      `${locationURLs[process.env.REACT_APP_URL] || ''}/getGlobalOKR`,
      {
        method: 'post',
        body: JSON.stringify({ name: 'nps' }),
        headers: { 'Content-type': 'application/json' },
      },
    ).then((response) =>
      response.json().then((json) => setGlobalNPS({ nps: json.data })),
    );
  }, []);

  const handleQuarterChange = (event, newQuarter) => {
    months = newQuarter;
    setQuarterMonth(newQuarter);
    //console.log(months);
  };

  tseMembersTeam = tseMembers.filter((tse) => tse.active === true);
  // console.log('dashboard');
  //console.log(tseMembers)
  // console.log(tseMembersTeam);

  useEffect(() => {
    const loadTable = async () => {
      tsesData = [];
      var quarterData = await getQuarterData();
      //console.log(quarterData);
      var tseJson = getTSEJson(quarterData, tseMembersTeam);
      //console.log(tseJson);
      tsesData = calculateTSEQuarterResults(tsesData);
      //console.log(tsesData);
      var allTseData = await getAllTseData(tsesData);
      //console.log(allTseData);

      //specific case of none data for the quarter
      if (allTseData.length == 0) {
        allTseData = nullDataTemplate;
      }

      // if (sessionStorage.getItem('name') != null) {
        allTseData = await TSEactivityRate(allTseData);
      // }

      //set data to display
      // console.log(allTseData);
      setAllTseData(allTseData);

      //set specific data on dashboard
      setTsesGlobalData(tsesData);

      console.log('your raw data', allTseData);
      console.log('current global raw data', {
        totalDifficultyScore: tsesData.difficultyScoreTally,
        totalWorkRate: tsesData.workRateTally,
        cdc: tsesData.averageCdc,
      });
    };

    loadTable();
  }, [view, quarterMonth]);

  return (
    <Page title='Okr & Stats'>
      <Container maxWidth='xl' className='team_list'>
        <Grid container spacing={3}>
          {allTseData.map((tse, index) => {
            return (
              <Container key={index} maxWidth='xl'>
                <Box sx={{ mb: 0.5, mx: 2 }}>
                  <Typography variant='h5'>{tse.fullName}</Typography>
                </Box>
                <Box component='div' sx={{ display: 'flex', mb: 5 }}>
                  <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                    <AppCDCBA data={tse} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} sx={{ mx: 2 }}>
                    <AppCampaigns data={tse} />
                  </Grid>
                </Box>
              </Container>
            );
          })}
        </Grid>
      </Container>
    </Page>
  );
};

export default DashboardBA;
